import mock from "../mock";

// import * as firebase from "firebase/app";
// import * as _ from "lodash";
// import moment from "moment";

// const loadFirebase = () => {
//   let dbRef = firebase
//     .database()
//     .ref("IRM_Scanner_Exams")
//     .orderByChild("exam_date");

//   dbRef.once("value").then((snapshot) => {
//     let resLogs = snapshot.val();
//     let dataList = [];
//     for (let l in resLogs) {
//       dataList.push(resLogs[l]);
//     }
//     console.log("finished loaded dataList");
//     return dataList;
//   });
// };

// export let dataList = loadFirebase().then((res) => {
//   console.log("res = " + res);
//   return res;
// });
export let dataList = [
  {
    name: "Mousdiouf",
    date: "02/10/2020",
    status_order: "delivered",
  },
];

const determinePopularity = (val) => {
  if (val >= 75) return { popValue: val, color: "success" };
  else if (val < 75 && val >= 55) return { popValue: val, color: "primary" };
  else if (val < 55 && val >= 35) return { popValue: val, color: "warning" };
  else if (val < 35 && val >= 0) return { popValue: val, color: "danger" };
  else return { popValue: 0, color: "danger" };
};

// GET DATA
mock.onGet("/api/datalist/initial-data").reply((response) => {
  return [200, dataList];
});

mock.onGet("/api/datalist/data").reply((response) => {
  let { page, perPage } = response;

  console.log("dataList = " + dataList);

  let totalPages = Math.ceil(dataList.length / perPage);
  if (page !== undefined && perPage !== undefined) {
    let calculatedPage = (page - 1) * perPage;
    let calculatedPerPage = page * perPage;
    return [
      200,
      { data: dataList.slice(calculatedPage, calculatedPerPage), totalPages },
    ];
  } else {
    return [
      200,
      {
        data: dataList.slice(0, 4),
        totalPages: Math.ceil(dataList.length / 4),
      },
    ];
  }
});

// UPDATE DATA
mock.onPost("/api/datalist/update-data").reply((request) => {
  let data = JSON.parse(request.data).obj;
  dataList.map((item) => {
    if (item.id === data.id) {
      let popularity = determinePopularity(data.popularity.popValue);
      return Object.assign(item, { ...data, popularity });
    } else {
      return item;
    }
  });
  return [200];
});

// Add DATA
mock.onPost("/api/datalist/add-data").reply((request) => {
  let data = JSON.parse(request.data).obj;
  let highestId = Math.max.apply(
    Math,
    dataList.map((i) => i.id)
  );
  dataList.unshift({
    ...data,
    id: highestId + 1,
    popularity: determinePopularity(data.popularity.popValue),
  });
  return [200];
});

// DELETE DATA
mock.onPost("/api/datalist/delete-data").reply((request) => {
  let data = JSON.parse(request.data).obj;
  let index = dataList.findIndex((item) => item.id === data.id);
  dataList.splice(index, 1);
  return [200];
});

// DELETE SELECTED DATA
mock.onPost("/api/datalist/delete-selected").reply((request) => {
  let data = JSON.parse(request.data).arr;
  let reducedArray;
  [dataList, data].reduce((a, b) => {
    let c = b.map((j) => j.id);
    return (reducedArray = a.filter((i) => !c.includes(i.id)));
  });
  dataList = reducedArray;
  return [200];
});
