import mock from "../mock";
import * as firebase from "firebase/app";
import * as _ from "lodash";

let firebaseAuth = firebase.auth();
let data;
firebaseAuth.onAuthStateChanged(function (user) {
	if (user) {
		let dbRef = firebase.database().ref("SMS");
		let contacts = [];
		let data;
		dbRef.once("value").then((snapshot) => {
			let res = snapshot.val();
			let chats = {};
			let ind = 1;
			let grouped_to = _.groupBy(res, "to_nb");
			let grouped_from = _.groupBy(res, "from_nb");
			let grouped = { ...grouped_to };
			let uid = 1;
			for (let nb in grouped_to) {
				if (!contacts.includes(nb)) {
					contacts.push({
						uid: uid,
						displayName: nb,
						about: "whatever",
						photoURL: require("../../assets/img/portrait/small/avatar-s-1.jpg"),
						status: "offline",
					});
					uid++;
				}

				// console.log(grouped_from);
				// grouped[nb] = {grouped_from[nb], ...grouped_to[nb]}
			}
			// console.log(contacts);

			// for (let el in res){
			//   chats[ind] = {
			//     isPinned: false,
			//     msg: [
			//       {
			//         text:
			//       }
			//     ]
			//   }

			//   ind++;
			// }
			data = {
				contacts: contacts,
				chats: {
					1: {
						isPinned: true,
						msg: [
							{
								textContent:
									"How can we help? We're here for you!",
								time: "Mon Aug 21 2020 07:45:00 GMT+0000 (GMT)",
								isSent: true,
								isSeen: true,
							},
							{
								textContent:
									"Hey John, I am looking for the best admin template. Could you please help me to find it out?",
								time: "Mon Aug 21 2020 07:45:23 GMT+0000 (GMT)",
								isSent: false,
								isSeen: true,
							},
							{
								textContent:
									"It should be Bootstrap 4 compatible.",
								time: "Mon Aug 21 2020 07:45:55 GMT+0000 (GMT)",
								isSent: false,
								isSeen: true,
							},
							{
								textContent: "Absolutely!",
								time: "Mon Aug 21 2020 07:46:00 GMT+0000 (GMT)",
								isSent: true,
								isSeen: true,
							},
							{
								textContent:
									"Modern admin is the responsive bootstrap 4 admin template.!",
								time: "Mon Aug 21 2020 07:46:05 GMT+0000 (GMT)",
								isSent: true,
								isSeen: true,
							},
							{
								textContent: "Looks clean and fresh UI.",
								time: "Mon Aug 21 2020 07:46:23 GMT+0000 (GMT)",
								isSent: false,
								isSeen: true,
							},
							{
								textContent:
									"It's perfect for my next project.",
								time: "Mon Aug 21 2020 07:46:33 GMT+0000 (GMT)",
								isSent: false,
								isSeen: true,
							},
							{
								textContent: "How can I purchase it?",
								time: "Mon Aug 21 2020 07:46:43 GMT+0000 (GMT)",
								isSent: false,
								isSeen: true,
							},
							{
								textContent: "Thanks, from ThemeForest.",
								time: "Mon Aug 21 2020 07:46:53 GMT+0000 (GMT)",
								isSent: true,
								isSeen: true,
							},
							{
								textContent: "I will purchase it for sure 👍.",
								time: "Mon Aug 21 2020 07:47:00 GMT+0000 (GMT)",
								isSent: false,
								isSeen: false,
							},
						],
					},
				},
			};
		});
	}
});

// Contact

// GET : CHATS AND CONTACTS
mock.onGet("/api/app/chat/chats").reply(() => {
	return [200, data];
});

// GET : CHAT LIST
mock.onGet("/api/app/chat/chat-contacts").reply((request) => {
	const chatContactsArray = data.contacts.filter((contact) => {
		if (data.chats[contact.uid]) return data.chats[contact.uid];
		else return null;
	});

	return [200, chatContactsArray];
});

// POST : SEND MESSAGE
mock.onPost("api/app/chat/send-message").reply((request) => {
	let reqdata = JSON.parse(request.data);
	const { contactId, message, isPinned } = reqdata;
	console.log(reqdata);
	console.log(data.chats);
	if (data.chats[contactId]) {
		data.chats[contactId].msg.push(message);
	} else {
		let newMsg = {
			[contactId]: {
				isPinned: isPinned,
				msg: [message],
			},
		};
		Object.assign(data.chats, newMsg);
	}
	return [200];
});
mock.onPost("/api/apps/chat/mark-all-seen/").reply((request) => {
	let contactId = JSON.parse(request.data).contactId;

	// Get chat data
	let marked = data.chats[contactId];

	marked !== undefined &&
		marked.msg.forEach((msg) => {
			msg.isSeen = true;
		});

	return [200];
});
// POST : TOGGLE PINNED
mock.onPost("/api/apps/chat/set-pinned/").reply((request) => {
	let { contactId, value } = JSON.parse(request.data);
	data.chats[contactId].isPinned = data.chats[contactId].isPinned = value;
	return [200];
});
