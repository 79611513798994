import React from "react";
import {
	NavItem,
	NavLink,
	UncontrolledDropdown,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
	Media,
	Badge,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import * as Icon from "react-feather";
import classnames from "classnames";
import ReactCountryFlag from "react-country-flag";
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent";
import { useAuth0 } from "../../../authServices/auth0/auth0Service";
import { history } from "../../../history";
import { IntlContext } from "../../../utility/context/Internationalization";

import { logoutWithFirebase } from "../../../redux/actions/auth/loginActions";
import * as firebase from "firebase/app";

const handleNavigation = (e, path) => {
	e.preventDefault();
	history.push(path);
};

const UserDropdown = (props) => {
	const { logout, isAuthenticated } = useAuth0();
	return (
		<DropdownMenu right>
			<DropdownItem
				tag="a"
				href="/"
				onClick={(e) => {
					e.preventDefault();
					firebase
						.auth()
						.signOut()
						.then(function () {
							history.push("/");
						})
						.catch(function (error) {
							// An error happened.
						});

					// if (isAuthenticated) {
					// 	return logout({
					// 		returnTo:
					// 			window.location.origin +
					// 			process.env.REACT_APP_PUBLIC_PATH,
					// 	});
					// } else {
					// 	const provider = props.loggedInWith;
					// 	if (provider !== null) {
					// 		props.logoutWithFirebase();
					// 	} else {
					// 		history.push("/");
					// 	}
					// }
				}}
			>
				<Icon.Power size={14} className="mr-50" />
				<span className="align-middle">Déconnexion</span>
			</DropdownItem>
		</DropdownMenu>
	);
};

class NavbarUser extends React.PureComponent {
	state = {
		navbarSearch: false,
		langDropdown: false,
		shoppingCart: [],
		suggestions: [],
	};

	componentDidMount() {
		axios.get("/api/main-search/data").then(({ data }) => {
			this.setState({ suggestions: data.searchResult });
		});
	}

	handleNavbarSearch = () => {
		this.setState({
			navbarSearch: !this.state.navbarSearch,
		});
	};

	removeItem = (id) => {
		let cart = this.state.shoppingCart;

		let updatedCart = cart.filter((i) => i.id !== id);

		this.setState({
			shoppingCart: updatedCart,
		});
	};

	handleLangDropdown = () =>
		this.setState({ langDropdown: !this.state.langDropdown });

	render() {
		const renderCartItems = this.state.shoppingCart.map((item) => {
			return (
				<div className="cart-item" key={item.id}>
					<Media
						className="p-0"
						onClick={() =>
							history.push("/ecommerce/product-detail")
						}
					>
						<Media className="text-center pr-0 mr-1" left>
							<img
								className={`${
									item.imgClass
										? item.imgClass + " cart-item-img"
										: "cart-item-img"
								}`}
								src={item.img}
								width={item.width}
								alt="Cart Item"
							/>
						</Media>
						<Media className="overflow-hidden pr-1 py-1 pl-0" body>
							<span className="item-title text-truncate text-bold-500 d-block mb-50">
								{item.name}
							</span>
							<span className="item-desc font-small-2 text-truncate d-block">
								{item.desc}
							</span>
							<div className="d-flex justify-content-between align-items-center mt-1">
								<span className="align-middle d-block">
									1 x {item.price}
								</span>
								<Icon.X
									className="danger"
									size={15}
									onClick={(e) => {
										e.stopPropagation();
										this.removeItem(item.id);
									}}
								/>
							</div>
						</Media>
					</Media>
				</div>
			);
		});

		return (
			<ul className="nav navbar-nav navbar-nav-user float-right">
				<UncontrolledDropdown
					tag="li"
					className="dropdown-user nav-item"
				>
					<DropdownToggle
						tag="a"
						className="nav-link dropdown-user-link"
					>
						<div className="user-nav d-sm-flex d-none">
							<span className="user-name text-bold-600">
								{this.props.userName}
							</span>
							<span className="user-status">En ligne</span>
						</div>
						<span data-tour="user">
							<img
								src={this.props.userImg}
								className="round"
								height="40"
								width="40"
								alt="avatar"
							/>
						</span>
					</DropdownToggle>
					<UserDropdown {...this.props} />
				</UncontrolledDropdown>
			</ul>
		);
	}
}
export default NavbarUser;
